import ymaps from 'ymaps'

let mapInited = false

function init() {
  window.addEventListener('scroll', mapsInit, { once: true })
  document.addEventListener('click', mapsInit, { once: true })
  setTimeout(mapsInit, 2000)
}

function mapsInit() {
  if (mapInited) return
  mapInited = true

  if (document.getElementById('map') && window.locations?.length) {
    ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then((maps: any) => {
      // const coords = window.coords
      // const center = [coords[0], coords[1]]

      const bounds = window.locations.reduce<number[][]>(
        (acc, current) => {
          return [
            [Math.min(acc[0][0], current.coords[0]), Math.min(acc[0][1], current.coords[1])],
            [Math.max(acc[1][0], current.coords[0]), Math.max(acc[1][1], current.coords[1])],
          ]
        },
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      )

      const options =
        window.locations.length > 1
          ? { bounds }
          : {
            center: window.locations[0]?.coords,
            zoom: 17,
          }

      const map = new maps.Map('map', options)

      window.locations.forEach((location) => {
        const placemark = new maps.Placemark(
          location.coords,
          {},
          {
            iconLayout: 'default#image',
            iconImageHref: '../img/geo.png',
            iconImageSize: [74, 84],
            iconImageOffset: [-(74 / 2), -84],
          }
        )

        map.geoObjects.add(placemark)
      })

      map.controls.remove('geolocationControl')
      map.controls.remove('searchControl')
      map.controls.remove('trafficControl')
      map.controls.remove('typeSelector')
      map.controls.remove('fullscreenControl')
      // map.controls.remove('zoomControl')
      map.controls.remove('rulerControl')
      map.behaviors.disable(['scrollZoom'])
    })
  }
}

init()

interface CustomWindow extends Window {
  coords: [number, number]
  locations: Location[]
}

interface Location {
  coords: [number, number]
  title: string
  shedule: string[]
  weekend: string
}

declare let window: CustomWindow
